import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-redux",
  "date": "2016-01-07",
  "title": "REACT & REDUX",
  "author": "admin",
  "tags": ["development", "javascript", "react.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Redux allows you to manage the state with a minimal API but completely predictable behaviour – a predictable state container for JavaScript apps. Redux is a Flux implementation but that does not use Flux. It is inspired by functional programming and immutability (Elm, Clojure) and written by Dan Abramov."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Redux allows you to manage the state with a minimal API but completely predictable behaviour - a predictable state container for JavaScript apps.`}</p>
    <p>{`Redux is a Flux implementation but that does not use Flux. It is inspired by functional programming and immutability (Elm, Clojure) and written by `}<inlineCode parentName="p">{`Dan Abramov`}</inlineCode>{`.`}</p>
    <h2>{`Why Redux?`}</h2>
    <p>{`Redux makes you think of your application as an initial state being modified by a sequential list of actions. Redux enables tools like logging, hot reloading, time travel, record and replay with no extra work.`}</p>
    <p>{`Redux is:`}</p>
    <ul>
      <li parentName="ul">{`Simple, conceptually and in file size (2kb)`}</li>
      <li parentName="ul">{`Has predictable state transitions`}</li>
      <li parentName="ul">{`Single source of truth for UI state`}</li>
      <li parentName="ul">{`Highly performant `}</li>
      <li parentName="ul">{`Easily testable`}</li>
    </ul>
    <h2>{`The Core Concepts of Redux`}</h2>
    <p>{`To understand Redux, we will look into the following concepts: `}</p>
    <ul>
      <li parentName="ul">{`Actions and action creators`}</li>
      <li parentName="ul">{`Reducers`}</li>
      <li parentName="ul">{`Store`}</li>
      <li parentName="ul">{`Middleware`}</li>
    </ul>
    <h2>{`Actions`}</h2>
    <p>{`Actions are payloads of information that send data from your application to your store. They are the only source of information for the store. You send them to the store using `}<strong parentName="p">{`store.dispatch()`}</strong>{`.`}</p>
    <p>{`Actions at its core is a simple JavaScript object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export addTodo = (todo) => {
  return {
    type: 'addTodo',
    todo
  }
}

export deleteTodo = (index) => {
  return {
    type: 'deleteTodo',
    index
  }
}
`}</code></pre>
    <h3>{`Type`}</h3>
    <p>{`The only the required field is `}<strong parentName="p">{`type`}</strong>{`. Only use constants for declaring types.`}</p>
    <h3>{`Payload`}</h3>
    <p>{`The `}<strong parentName="p">{`payload`}</strong>{` generally carries your data.`}</p>
    <h3>{`Action Creators`}</h3>
    <p>{`Action creators are the functions that return the body of an action.`}</p>
    <h2>{`Reducers`}</h2>
    <p>{`Actions describe the fact that something happened, but don’t specify how the application’s state changes in response. This is the job of a reducer. It updates the state according to those actions.`}</p>
    <p>{`Reducers are pure functions with the signature `}<strong parentName="p">{`(previousState, action) => newState`}</strong>{`.`}</p>
    <Message type="warn" title="Time Travel" content="Make sure that you should never mutate the `previousState` in your reducer. Instead you can create a new object based on the `previousState` properties. Otherwise bad things will happen (for starters you will break time travel). " mdxType="Message" />
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Immutable from 'immutable'

export default (state = Immutable.List(['Code More!']), action) => {
  switch(action.type) {
    case 'addTodo':
      return state.push(action.todo)
    default:
      return state
  }
}
`}</code></pre>
    <p>{`We are using the `}<strong parentName="p">{`immutable`}</strong>{` package here to make sure that the state must be immutable.`}</p>
    <h2>{`Store`}</h2>
    <p>{`Store is a wrapper around a JavaScript object (state).
A store has two key methods: `}<strong parentName="p">{`getState`}</strong>{`, and `}<strong parentName="p">{`dispatch`}</strong>{`.
If you are using something like `}<inlineCode parentName="p">{`react-redux`}</inlineCode>{`, you don’t have to deal with these functions directly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createStore } from 'redux';
import todos from './reducers/todos';

export default createStore(todos);
`}</code></pre>
    <h2>{`Middelware`}</h2>
    <p>{`If you are familiar with Node.js middleware like `}<inlineCode parentName="p">{`Express`}</inlineCode>{` or `}<inlineCode parentName="p">{`Koa`}</inlineCode>{`, Redux middleware works similar to them except, it solves different problems.`}</p>
    <p>{`It provides a third-party extension point between dispatching an action, and the moment it reaches the reducer. People use Redux middleware for logging, crash reporting, talking to an asynchronous API, routing, and more.`}</p>
    <p>{`Recommended middleware: `}<inlineCode parentName="p">{`thunk`}</inlineCode>{`, `}<inlineCode parentName="p">{`redux-promise`}</inlineCode>{` and `}<inlineCode parentName="p">{`batched-updates`}</inlineCode>{`.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Redux is powerful for building predictable, testable, maintainable interfaces. Redux makes you a better UI engineer by forcing you to handle state changes explicitly.`}</p>
    <p>{`You can find the source code in Github: `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/react-redux"
      }}>{`React Redux`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      